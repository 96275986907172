/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

/**
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component
 */
@import "all/custom/variables.scss";

// Import Bootstrap source files (Required file)
@import "all/bootstrap/bootstrap.scss";

@import "all/custom/custom.scss";

//This is for the icons (Required file)
@import "all/icons/font-awesome/css/fontawesome-all.css";
@import "all/icons/simple-line-icons/css/simple-line-icons.css";
@import "all/icons/weather-icons/css/weather-icons.css";
@import "all/icons/themify-icons/themify-icons.css";
@import "all/icons/flag-icon-css/flag-icon.min.css";
@import "all/icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "all/icons/crypto-icons/cryptocoins.css";

.bootstrap-switch {
  display: block !important;
}

.logo-text {
  width: 100%;
  img {
    width: 100%;
  }
}

.trade-story {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.trade-story-pagination {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  padding: 20px 50px 0;
}

.react_select__menu {
  color: #000 !important;
}

.rdt {
  border: 1px rgba(233, 236, 239, 0.75) solid;
  border-radius: 5px;
  min-height: 38px;
  input {
    background-color: #fff !important;
    border: none !important;
  }
}

.trade-details {
  i {
    margin-right: 7px;
    font-size: 18px;
    color: #fff;
    opacity: 0.5;
  }
  opacity: 0.8;

  &:hover {
    cursor: pointer;
    i {
      opacity: 1;
    }
    opacity: 1;
  }
}

.trade-close {
  margin-right: 7px;
  opacity: 0.7;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    color: rgb(250, 0, 0);
  }
  i {
    font-size: 23px;
  }
  span {
    font-size: 20px;
    padding: 5px;
  }
}

.account-close {
  margin-right: 7px;
  opacity: 0.7;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    color: rgb(250, 0, 0);
  }
  i {
    font-size: 23px;
  }
  span {
    font-size: 20px;
    padding: 5px;
  }
}

.account_name {
  font-weight: 600;
  font-size: 18px;

  opacity: 0.7;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.statistic_selects {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.statistic_cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 40px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.apexcharts-legend-text {
  //color: #000 !important;
  //-webkit-text-stroke: 0.3px #fff !important;
}
